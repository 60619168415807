<script setup>
import PlaceToBook from '../components/PlaceToBook.vue'
import { onBeforeMount } from "vue"
import { ref } from '@vue/reactivity'
import lodash from 'lodash'
import { useStore } from '@/store'

const store = useStore()
const loading = ref(true);
const bookingPlaces = ref([]);

onBeforeMount(async () => {
  await store.dispatch('loadBookingPlaces');
  await store.dispatch('loadCalendarEvents');

  bookingPlaces.value = store.getters.bookingPlaces;
  loading.value = false;
 });

// const rows = [{
//   "Nazwa": "test",
//   "Id": "1",
//   "Type": "typ",
//   "Link do zdjęcia": "https://m43cowork.com/wp-content/uploads/2020/10/pakiet-korporacyjny-2.jpg"
// }];
</script>
<template>
  <v-app>
  
        <div class="text-center" v-if="loading">
          <v-progress-circular :size="100" color="primary" indeterminate>
          </v-progress-circular>
        </div>
        <v-row justify="center" no-gutters v-if="!loading">
          <v-col class="primary lighten-2 py-4 text-center white--text" v-bind:key="row" v-for="row in lodash.groupBy(bookingPlaces, 'Typ')" sm="12"
            md="6">
            <PlaceToBook :title="row[0].Nazwa" :id="row[0].Id" :type="row[0].Typ" :img="row[0]['Link do zdjęcia']">
            </PlaceToBook>
          </v-col>
        </v-row>
  </v-app>
</template>



import { useStore, BookingPlace, CalendarEvent } from '@/store'

const store = useStore()

export function isPlaceAvailable(type: String, from: Date, to: Date) {
  const bookingPlaces = store.getters.bookingPlaces;
  const calendarEvents = store.getters.calendarEvents;
  const filteredCalendarEventsByType = calendarEvents.filter((event:any) => event.type === type);
  const filteredBookingPlacesByType = bookingPlaces.filter((place:BookingPlace) => place.Typ === type);
  if (filteredBookingPlacesByType.length === 0) {
    return false;
  }

  let isPlaceAvailable = true;
  filteredBookingPlacesByType.forEach((place:BookingPlace) => {
    isPlaceAvailable = true;
    filteredCalendarEventsByType.filter((calendarEvent:CalendarEvent) => calendarEvent.placeId == place.Id).forEach((calendarEvent:any) => {
      // TODO Add place availability checker first
      // Shoudl we even care? 
      // if (from.getHours() <= parseInt(place['Dostepne od'].split(":")[0]) 
      // && from.getMinutes() <= parseInt(place['Dostepne od'].split(":")[1])
      // && to.getHours() >= parseInt(place['Dostępne do'].split(":")[0])
      // && to.getMinutes() >= parseInt(place['Dostępne do'].split(":")[1])) {
      //   isPlaceAvailable = false;
          //  return;
      // }

      // NOTE Check if place is available based the existing events
      if ((to > calendarEvent.from && from < calendarEvent.from)
        || (from < calendarEvent.to && to > calendarEvent.from)
        || (from > calendarEvent.from && to < calendarEvent.to)) {
        isPlaceAvailable = false;
        return;
      }
    });
  });
  return isPlaceAvailable;
}
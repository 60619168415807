import { createStore } from "vuex";
import axios from "axios";

interface State {
  bookingPlaces: BookingPlace[];
  calendarEvents: CalendarEvent[];
}

export interface BookingPlace {
  Id: string;
  Nazwa: string;
  Typ: string;
  "Cena za h": string;
  "Cena za dzień": string;
  "Cena za miesiąc": string;
  "Dostepne od": string;
  "Dostępne do": string;
  "Link do zdjęcia": string;
}

export interface CalendarEvent {
  from: Date;
  to: Date;
  fullName: string;
  type: string;
  placeId: string;
}

const state = {
  bookingPlaces: [],
  calendarEvents: [],
};

const getters = {
  bookingPlaces: (state: State) => state.bookingPlaces,
  calendarEvents: (state: State) => state.calendarEvents,
};
const mutations = {
  SET_BOOKING_PLACES(state: State, places: BookingPlace[]) {
    state.bookingPlaces = places;
  },
  SET_CALENDAR_EVENTS(state: State, events: CalendarEvent[]) {
    state.calendarEvents = events;
  },
};
const actions = {
  loadBookingPlaces({ commit }: any) {
    return axios
      .get(`${process.env.VUE_APP_GOOGLE_APP_URL}/google/booking-places`)
      .then((response: any) => response.data)
      .then((bookingPlaces: any) => {
        commit("SET_BOOKING_PLACES", bookingPlaces);
      });
  },
  loadCalendarEvents({ commit }: any) {
    return axios
      .get(`${process.env.VUE_APP_GOOGLE_APP_URL}/google/calendar-events`)
      .then((response: any) => response.data)
      .then((calendarEvents: any) => {
        calendarEvents.forEach((event: any) => {
          event.fullName = event.name;
          event.type = event.name.split(";")[0];
          event.placeId = event.name.split(";")[1];
          event.from = new Date(event.from);
          event.to = new Date(event.to);
        });
        commit("SET_CALENDAR_EVENTS", calendarEvents);
      });
  },
};

export const store = createStore<State>({
  state,
  getters,
  actions,
  mutations,
});


export function useStore() { 
  return store; 
}

